
import { defineComponent } from 'vue';
import DefautLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { numberToMoney, formatAccountBankNumber } from '@/utils/index';
import BaseModal from '@/components/Base/BaseModal.vue';
import { mapGetters } from 'vuex';
// import { createTransfer } from '@/apis/ApiBank';
import constRouter from '@/constants/constRouter';
// import constRouter from '@/constants/constRouter';
export default defineComponent({
  components: {
    DefautLayout,
    BaseButton,
    BaseModal,
  },
  data() {
    return {
      numberToMoney,
      formatAccountBankNumber,
      loading: false,
      openModal: false,
      dataModal: {},
      data: {},
    };
  },
  computed: {
    ...mapGetters({
      contractInfo: 'getTransferInfo',
      bankTransfer: 'getBankInfo',
    }),
  },
  created() {
    const data = JSON.parse(localStorage.getItem('packakeList') || '[]');
    this.data = data[0];
  },
  // created() {
  //   if (
  //     Object.keys(this.contractInfo).length === 0 ||
  //     Object.keys(this.bankTransfer).length === 0
  //   ) {
  //     this.$router.push({
  //       name: constRouter.DASHBOARD.name,
  //     });
  //   }
  // },
  methods: {
    closeModal() {
      this.openModal = false;
    },
    handleConfirm() {
      this.loading = true;
      this.$router.push({
        name: constRouter.INVESTMENT_SUCCESS.name,
      });
      // createTransfer(
      //   {
      //     contractId: this.contractInfo.id,
      //     fromBankAccountId: this.bankTransfer.id,
      //     callBackUrl: window.location.origin + '/investment-result',
      //   },
      //   this.bankTransfer?.bank?.binCode,
      // )
      //   .then((res) => {
      //     location.href = res.data.redirectLink;
      //   })
      //   .catch((err) => {
      //     this.openModal = true;
      //     this.dataModal = err.data?.message;
      //     this.loading = false;
      //     console.log(err);
      //   });
    },
  },
});
